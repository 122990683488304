import { IconButton, InputAdornment } from "@mui/material";
import Input from "../Input/Input";
import Icon from "../Icon/Icon";
import { memo } from "react";

const SelectInput = memo(({ label, value, onClickSearch, hiddenSearch, hiddenDelete }) => {
    return (
        <Input
            formControlProps={{
                sx: {
                    width: '100%',
                }
            }}
            // disabled
            fullWidth
            value={value}
            label={label}
            onClick={() => onClickSearch('search')}
            inputBaseProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickSearch('delete');
                            }}
                            hidden={hiddenDelete}
                        >
                            <Icon
                                iconName={'close'}
                                svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                        height: '12px',
                                        width: '12px',
                                    }
                                }}
                            />
                        </IconButton>

                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickSearch('search');
                            }}
                            hidden={hiddenSearch}
                        >
                            <Icon
                                iconName={'search'}
                                svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                        height: '12px',
                                        width: '12px',
                                    }
                                }}
                            />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
});

export default memo(SelectInput);
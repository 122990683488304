import { Grid } from '@mui/material';
import React, { useState } from 'react';
import ButtonService from '../ButtonService';
import SelectDataCity from './Selects/SelectDataCity';
import ApiService from '../../../services/ApiService';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { CustomRadioGroup, Input } from '../../../components/ui';
import SelectInput from '../../../components/ui/SelectInput/SelectInput';
import SelectDataCOA from './Selects/SelectDataCOA';

const CreateEditBank = (props) => {
    const [isModalCity, setIsModalCity] = useState(false);
    const [titleModalCity, setTitleModalCity] = useState('');
    const paramCity = "city/city";
    const [isLoadingCity, setIsLoadingCity] = useState(false);
    const [dataCity, setDataCity] = useState([]);
    const [dataMapCity, setDataMapCity] = useState([])
    const [columnDataCity, setColumnDataCity] = useState([])
    const [SelectedDataCity, setSelectedDataCity] = useState({});
    const [totalRowsCity, setTotalRowsCity] = useState(50)


    const [isModalCOA, setIsModalCOA] = useState(false);
    const [titleModalCOA, setTitleModalCOA] = useState('');
    const paramCOA = "regChartOfAccount/regChartOfAccount";
    const [isLoadingCOA, setIsLoadingCOA] = useState(false);
    const [dataCOA, setDataCOA] = useState([]);
    const [dataMapCOA, setDataMapCOA] = useState([])
    const [columnDataCOA, setColumnDataCOA] = useState([])
    const [SelectedDataCOA, setSelectedDataCOA] = useState({});
    const [totalRowsCOA, setTotalRowsCOA] = useState(50)

    const dataCurrency = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ]

    const dataActive = [
        { id: 1, code: 0, name: 'Yes' },
    ]

    const Save = () => {
        var data = {
            rowStatus: props.isActive === 0? 'ACT' : 'DEL',
            id: props.id,
            intName: props.intName,
            name: props.name,
            address: props.address,
            contactPerson: props.contactPerson,
            eMail: props.email,
            fax: props.faxNumber,
            phone: props.phoneNumber,
            zipCode: props.postalCode,
            cityId: props.cityId,
            coaId: props.coaId,
            coaCode: props.coaCode,
            coaName: props.coaName,
            accountNo: props.acNumber,
            amountCRR: parseInt(props.amountCrr),
        };

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
            window.location.reload();
        });
    }

    function handleSetAmountCrr(val) {
        props.setAmountCrr('' + val);
    }

    function handleSetActive(val) {
        if (props.isActive === val) {
            props.setIsActive(1);
        } else {
            props.setIsActive(0);
        }
    }

    const handleModalCity = () => {
        if (isModalCity === false) {
            setIsModalCity(true);
            setTitleModalCity('City')
            getDataCity(1, 50)
        } else {
            setIsModalCity(false);
        }
    }

    const searchCity = (e) => {
        if (e === 'search') {
            handleModalCity();
        } else {
            props.setCityId(0);
            props.setCityName('');
        }
    }

    const getDataCity = (pageNumber, pageSize, filters) => {
        setIsLoadingCity(true);
        ApiService.DynamicPostByPage(paramCity, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataCity({})
                    setDataCity(response.data.data.city);

                    let temp = response.data.data.city
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapCity(indexed)
                    setColumnDataCity(response.data.data.columns)
                    setTotalRowsCity(response.data.totalRowCount)

                    setIsLoadingCity(false)
                }
                setIsLoadingCity(false)
            })
            .catch(function (error) {
                setIsLoadingCity(false)
                console.error('error saat fetch', error)
            })
    }

    const handleModalCOA = () => {
        if (isModalCOA === false) {
            setIsModalCOA(true);
            setTitleModalCOA('Chart of Account')
            getDataCOA(1, 50)
        } else {
            setIsModalCOA(false);
        }
    }

    const searchCOA = (e) => {
        if (e === 'search') {
            handleModalCOA();
        } else {
            props.setCOAId(0);
            props.setCOACode('');
            props.setCOAName('');
        }
    }

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingCOA(true);
        let params = [];
        ApiService.COAPostBySearch(paramCOA, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataCOA({})
                    setDataCOA(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapCOA(indexed)
                    setColumnDataCOA(response.data.data.columns)
                    setTotalRowsCOA(response.data.totalRowCount)

                    setIsLoadingCOA(false)
                }
                setIsLoadingCOA(false)
            })
            .catch(function (error) {
                setIsLoadingCOA(false)
                console.error('error saat fetch', error)
            })
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Grid container item direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} rowSpacing={'24px'} columnSpacing={'16px'}>
                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="intName"
                        label="Int. Code"
                        value={props.intName}
                        onChange={(e) => props.setIntName(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="bankName"
                        label="Bank Name"
                        value={props.name}
                        onChange={(e) => props.setName(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                name="address"
                label={'Address'}
                value={props.address}
                onChange={(e) => props.setAddress(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
                inputBaseProps={{
                    rows: 5,
                    sx: {
                        padding: '8px',
                    },
                }}
            />

            <Grid container item direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} rowSpacing={'24px'} columnSpacing={'16px'}>
                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="contactPerson"
                        label="Contact Person"
                        value={props.contactPerson}
                        onChange={(e) => props.setContactPerson(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <div style={{ marginTop: '16px' }}>
                        <CustomRadioGroup
                            title={'Currency'}
                            isChecked={props.amountCrr}
                            setIsChecked={handleSetAmountCrr}
                            dataGroup={dataCurrency}
                        />
                    </div>
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="acNumber"
                        label="A/C Number"
                        value={props.acNumber}
                        onChange={(e) => props.setACNumber(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <SelectInput
                        label={"Chart of Account"}
                        value={props.coaCode + ' - ' + props.coaName}
                        onClickSearch={(e) => searchCOA(e)}
                        hiddenDelete={props.coaId === 0}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="postalCode"
                        label="Postal Code"
                        value={props.postalCode}
                        onChange={(e) => props.setPostalCode(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="phone"
                        label="Phone"
                        value={props.phoneNumber}
                        onChange={(e) => props.setPhoneNumber(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="fax"
                        label="Fax"
                        value={props.faxNumber}
                        onChange={(e) => props.setFaxNumber(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <Input
                        name="email"
                        label="Email"
                        value={props.email}
                        onChange={(e) => props.setEmail(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={6} display={'flex'}>
                    <SelectInput
                        label={'City'}
                        value={props.cityName}
                        onClickSearch={(e) => searchCity(e)}
                        hiddenDelete={props.cityId === 0}
                    />
                </Grid>

                <Grid item xs={6} display={'flex'}>
                    <CustomRadioGroup
                        title={'Active'}
                        isChecked={props.isActive}
                        setIsChecked={handleSetActive}
                        dataGroup={dataActive}
                    />
                </Grid>
            </Grid>
        </form>
    )

    return (
        <>
            <CreateEditModalMaster
                isModal={props.isModal} titleModal={props.titleModal} title={props.title}
                mainContainer={mainContainer} handleClick={props.handleClick} handleSave={Save}
            />

            {
                isModalCity && (
                    <SelectDataCity
                        isModal={isModalCity}
                        handleClick={searchCity}
                        titleModal={titleModalCity}
                        setIsModal={setIsModalCity}

                        isLoading={isLoadingCity}
                        data={dataCity}
                        dataMap={dataMapCity}
                        columnData={columnDataCity}
                        SelectedData={SelectedDataCity}
                        totalRows={totalRowsCity}
                        setSelectedData={setSelectedDataCity}
                        getData={getDataCity}

                        setCityId={props.setCityId}
                        setCityName={props.setCityName}
                    />
                )
            }

            {
                isModalCOA && (
                    <SelectDataCOA
                        isModal={isModalCOA}
                        handleClick={searchCOA}
                        titleModal={titleModalCOA}
                        setIsModal={setIsModalCOA}

                        isLoading={isLoadingCOA}
                        data={dataCOA}
                        dataMap={dataMapCOA}
                        columnData={columnDataCOA}
                        SelectedData={SelectedDataCOA}
                        totalRows={totalRowsCOA}
                        setSelectedData={setSelectedDataCOA}
                        getData={getDataCOA}

                        setCOAId={props.setCOAId}
                        setCOACode={props.setCOACode}
                        setCOAName={props.setCOAName}
                    />
                )
            }
        </>
    )
}

export default CreateEditBank